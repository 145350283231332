import { Button, Tooltip } from "antd";
import { DrawerForm } from "@ant-design/pro-components";

const DrawerEdit = ({ title, form, initialValues, render, onFinish }) => {
  return (
    <DrawerForm
      title={`Edit ${title}`}
      form={form}
      initialValues={initialValues}
      trigger={
        <Button
          type="link"
          icon={<i className="far fa-edit"></i>}
        />
      }
      drawerProps={{
        destroyOnClose: true,
      }}
      onFinish={(values) => {
        onFinish(values);
        return true;
      }}
    >
      {render}
    </DrawerForm>
  );
};

export default DrawerEdit;
