import { useEffect, useState } from "react";
import {
  DrawerForm,
  ModalForm,
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormDatePicker,
  ProFormRadio,
  ProFormTextArea,
  PageContainer,
  ProFormGroup,
  ProFormDigit,
  ProFormUploadDragger,
  ProFormUploadButton,
  ProFormCheckbox,
  CheckCard
} from "@ant-design/pro-components";
import { CalendarTwoTone, Dragger, InboxOutlined, CloseOutlined, LeftOutlined, TrophyTwoTone, EditOutlined, SettingOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
  Result, 
  Spin,
  Flex,
  Tabs,
  Row,
  Col,
  Radio,
  Button,
  Space,
  Tag,
  Avatar,
  Typography,
  Image,
  Tooltip,
  Breadcrumb,
  Form,
  Dropdown,
  Badge,
  Card,
  Segmented,
  Table,
  List,
  Divider,
  AutoComplete,
  Modal,
  Calendar,
  Upload as AntdUpload,
} from "antd";
import moment from "moment-timezone";
import CountUp from "react-countup";
import debounce from "lodash/debounce";
import { Api } from "services";
import { addressFormat, generateUsername } from "utils";
import { Upload } from "components";
import "./index.css";

const { Title, Text, Link } = Typography;



const getListData = (value) => {
  let listData = []; // Specify the type of listData
  switch (value.date()) {
    case 8:
      listData = [
        {
          type: 'warning',
          content: 'This is warning event.',
        },
        {
          type: 'success',
          content: 'This is usual event.',
        },
      ];
      break;
    case 10:
      listData = [
        {
          type: 'warning',
          content: 'This is warning event.',
        },
        {
          type: 'success',
          content: 'This is usual event.',
        },
        {
          type: 'error',
          content: 'This is error event.',
        },
      ];
      break;
    case 15:
      listData = [
        {
          type: 'warning',
          content: 'This is warning event',
        },
        {
          type: 'success',
          content: 'This is very long usual event......',
        },
        {
          type: 'error',
          content: 'This is error event 1.',
        },
        {
          type: 'error',
          content: 'This is error event 2.',
        },
        {
          type: 'error',
          content: 'This is error event 3.',
        },
        {
          type: 'error',
          content: 'This is error event 4.',
        },
      ];
      break;
    default:
  }
  return listData || [];
};
const getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
};



const columns = [
  {
    title: 'Nominate',
    dataIndex: 'nominate',
    key: 'nominate',
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (_, record) => (<Text>{moment(record.created_at).format("DD/MM/YYYY, HH:mm:ss")}</Text>)
  },
];

  const listZone = [
    {
      id:1,
      title:'Common Area & Satoshi Meeting Room',
      detail:'Request to Book Common Area & Satoshi Meeting Room'
    }
  ]








export default function Page() {
  const [form] = Form.useForm();

  const [openModel, setOpenModel] = useState(false);
  const [step, setStep] = useState(0);
  const [allow, setAllow] = useState(false);
  const [tmpZone, setTmpZone] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [selectAward, setSelectAward] = useState(false);
  const [loading,setLoading] = useState(false);
  const [presentType, setPresentType] = useState(false);
  const [active, setActive] = useState("Entity");
  const [dataSource, setDataSource] = useState([]);
  const [dataForm, setDataForm] = useState(false);
  const [modal, contextHolder] = Modal.useModal()

  const fetchData = async (id) => {
    const result = await Api.Get(`/booking/view/${id}`);
    if (result.data.status === true) {
      setDataSource(result.data.data);
    }
  };

  const onOpenDetail = (data) => {
    // fetchData(data.id)
    setTmpZone(data)
  };

  const onCloseDetail = () => {
    setTmpZone(false);
    // form.setFieldsValue({
    //   "nominate": '',
    //   "image": [],
    //   "drive": '',
    //   "message_event": '',
    //   "message_result": '',
    //   "message_why": '',
    //   "option_doc": '',
    // });
  };

  async function handleSearch(keyword) {
    if (keyword == "") {
      setEmployee([]);
    } else {
      const response = await Api.Get(`/employee/search/${keyword}`);
      if (response.data.status === true) {
        if (response.data.data.length > 0) {
          let data = [];
          for (var i = 0; i < response.data.data.length; i++) {
            data.push({
              value: generateUsername(response.data.data[i].company_email),
              label: (
                <Space>
                  <Avatar src={response.data.data[i].avatar} size="small" />
                  <Text>
                    {generateUsername(response.data.data[i].company_email)}
                  </Text>
                </Space>
              ),
            });
          }
          setEmployee(data);
        } else {
          form.setFieldsValue({});
          setEmployee([]);
        }
      }
    }
  }

  const onFinish = async(value)=>{
    setStep(1)
    setDataForm(value)
  }

  const onEnd= async()=>{
    var res = await modal.confirm({
      title: 'Confirm',
      content: 'คลิกเพื่อยืนยันการเสนอชื่อประเภทรางวัลนี้',
      okText: 'Yes',
      cancelText: 'No',
    });
    if(res){
      
      setLoading(true);
      const response = await Api.Post(`/champion/save/${selectAward.id}`, dataForm);
      console.log('response===>',response,response.data.status)
      if (response.data.status === true) {
        setLoading(false);
        form.setFieldsValue({
          "nominate": '',
          "image": [],
          "drive": '',
          "message_event": '',
          "message_result": '',
          "message_why": '',
          "option_doc": '',
        });
        // modal.success({
        //   centered: true,
        //   title: "Successfully",
        //   content: <Text>{response.data.message}</Text>
        // });
        setStep(2)
      } else {
        setLoading(false);
        modal.error({
          centered: true,
          title: "Unsuccessfully",
          content: <Text>{response.data.message}</Text>
        });
      }
    }

  }

  const getFile = (image) => {
    form.setFieldsValue({
      image,
    });
  };




  const RenderZone = () => {
    return tmpZone==false && (<>
        <Text>** กรุณาทำการกดเลือกหัวข้อที่ต้องการ Nominate ได้เลย<br /><br /></Text>
        <Row gutter={16}>
          {listZone.map(item=>{
            return (
              <Col xs={24} md={12} lg={8}  style={{marginBottom:16}}>
                <Card bordered={false} hoverable style={{cursor:'pointer',height:'100%'}} onClick={()=>onOpenDetail(item)}>
                  <CalendarTwoTone twoToneColor="#24d37d" style={{fontSize:60,float:'left',marginRight:10}}/>
                  <Title level={5} style={{marginTop:0,marginBottom:8,padding:0,fontWeight:700}}>{item.title}</Title>
                  <Text>{item.detail}</Text>
                </Card>
              </Col>
            )
          })}
        </Row>
    </>)
  }



  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };
  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };
  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  const RenderCalendar = () => {
    return tmpZone!=false && (<>
        <Row gutter={16}>
            <Col xs={24} md={9} style={{marginBottom:16}}>
              <Card cover={<img alt="example" src="/bg_champion.png"/>} bordered={false} style={{marginBottom:16}}>
                <div>
                  <Button type="primary" icon={<CloseOutlined style={{fontSize:20,color:'#ffffff'}}/>} size={'large'} style={{float:'right',marginLeft:20,color:'#24d37d'}} onClick={()=>onCloseDetail()}/>
                  <Title level={3}>{tmpZone?.title}</Title>
                  <Text>{tmpZone?.detail}</Text>
                </div>
              </Card>
            </Col>
            <Col xs={24} md={15} style={{marginBottom:16}}>
              <ProForm
                onFinish={async (values) => {
                  console.log(values);
                }}
                submitter={{
                  searchConfig: {
                    submitText: "Next",
                  },
                  resetButtonProps: false,
                }}
              >
                <ProFormText
                  name="projectName"
                  label="ชื่อโครงการ / Project Name"
                  rules={[{ required: true, message: "Please enter the project name" }]}
                />

                <ProFormSelect
                  name="owner"
                  label="เจ้าของงาน / Project Owner"
                  options={[{ label: "@pop.warunchaya", value: "@pop.warunchaya" }]}
                />

                <ProFormSelect
                  name="team"
                  options={[{ label: "People Team", value: "People Team" }]}
                />

                <ProFormSelect
                  name="department"
                  options={[{ label: "BCGH", value: "BCGH" }]}
                />

                <ProFormRadio.Group
                  name="layout"
                  label="รูปแบบงาน / Type of Event"
                  options={[
                    { label: "งานประชุม - สัมมนา", value: "meeting" },
                    { label: "งานอีเวนท์", value: "event" },
                    { label: "จัดกิจกรรม", value: "activity" },
                    { label: "Other", value: "other" }
                  ]}
                />

                <ProFormText
                  name="layout_other"
                  label="อื่นๆ (กรณีเลือก Other)"
                  dependencies={["eventType"]}
                  hidden={(values) => values?.eventType !== "other"}
                />

                <ProFormDigit
                  name="attendees"
                  label="จำนวนผู้เข้าร่วม / Number of Attendees"
                  fieldProps={{ min: 1 }}
                  rules={[{ required: true, message: "Please enter the number of attendees" }]}
                />


                <Text>รูปแบบการจัด Layout / Layout Design</Text>





                <ProFormRadio.Group
                  name="use_led"
                  label="ท่านต้องการใช้จอ LED ที่ Zilla Space ไหม / Would you like to use an LED screen?"
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" }
                  ]}
                />

                {/* LED Screen Choices */}
                <ProFormRadio.Group
                  name="led"
                  label="โปรดเลือกจอที่ท่านการใช้ / Please select the LED screen you would like to use."
                  options={[
                    { label: "ZL001", value: "ZL001" },
                    { label: "ZL002 - ZL005", value: "ZL002-ZL005" },
                    { label: "ZL006", value: "ZL006" },
                    { label: "ZL007", value: "ZL007" },
                    { label: "ZL008", value: "ZL008" },
                    { label: "ZL009", value: "ZL009" },
                    { label: "Other", value: "other" }
                  ]}
                  dependencies={["useLedScreen"]}
                />

                {/* Upload Field if "Other" is Selected */}
                <ProFormUploadButton
                  name="led_other"
                  label="อัพโหลดรูปภาพจอ LED / Upload LED screen image"
                  max={1}
                  fieldProps={{
                    accept: "image/*"
                  }}
                  dependencies={["ledScreen"]}
                  rules={[{ required: true, message: "Please upload an image for the LED screen" }]}
                />

                {/* Confirmation */}
                <ProFormRadio.Group
                  name="led_agree"
                  label="โปรดส่งไฟล์ก่อนวันงาน 1-2 วัน และกรุณาตั้งชื่อไฟล์ตามนี้ ZL001_ชื่องาน (แนบลิงค์ Google Drive ได้ที่ Slack @ploy.ploypailin.a.)"
                  options={[{ label: "รับทราบ", value: "acknowledged" }]}
                  rules={[{ required: true, message: "กรุณายืนยันการรับทราบ" }]}
                />


                <ProFormRadio.Group
                  name="food"
                  label="ท่านต้องการอาหารหรือเครื่องดื่มหรือไม่"
                  options={[
                    { label: "yes", value: "ต้องการ" },
                    { label: "no", value: "ไม่ต้แงการ" }
                  ]}
                />

                <ProFormCheckbox
                  name="agree"
                  label="ท่านรับทราบ และมีหน้าที่ให้ผู้เข้าร่วมงานเข้าใจในการถ่ายรูปภาพและวิดีโอ และรับทราบถึงจุดประสงค์การจัดงาน เข้าร่วมงานให้แจ้งสตาฟ เดินผ่านไปมา ป้ายตั้ง PDPA หากภายในงานของท่านมีการถ่ายรูปและวิดีโอ ในสื่อภายในและภายนอกองค์กร ท่านต้องยอมรับ และประสานงานกับ People Team เพื่อทำการประกาศให้บุคคลภายในได้รับทราบถึงรายละเอียดต่าง ๆ In the case of recording videos or taking photos, it is necessary to comply with the Personal Data Protection Act (PDPA). We would like to inform others in advance before capturing their image or voice. We are committed to protecting personal data and respecting privacy rights."
                  options={[
                    { label:'ยอมรับ', value: "yes" }
                  ]}
                  />



              </ProForm>

            </Col>
        </Row>
    </>)
  }

  const RenderForm = () => {
    return (<>Form</>)
  }

  const RenderHistory = () => {
    return (<>History</>)
  }

  return (
    <PageContainer
      header={{
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/profile",
              title: "My Profile",
            },
            {
              path: "/booking",
              title: "Booking",
            },
          ],
        },
      }}
    >


      <RenderZone />
      <RenderCalendar />

      {contextHolder}



    </PageContainer>
  );
}
